import devConfig from './config.dev.json';
import prodConfig from './config.prod.json';

/**
 * Global configuration
 */
interface IConfig {
  /** GraphQL API URL */
  graphqlApiUrl: string;

  /** REST API Base URL */
  restApiBase: string;
}

/**
 * Get the appropriate config JSON based on NODE_ENV
 * @returns Typed configuration
 */
function getConfig(): IConfig {
  if (process.env.NODE_ENV === "production") {
    return prodConfig;
  } else {
    return devConfig;
  }
}

export const Config = getConfig();