import './index.scss';
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    createHttpLink,
} from '@apollo/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Config } from 'src/config';
// import { HomePage } from 'src/pages/home';
import { LoginPage } from 'src/pages/login';
import { SearchPage } from 'src/pages/search';
import React from 'react';
import ReactDOM from 'react-dom';

const GQL_SERVER = createHttpLink({
    uri: Config.graphqlApiUrl,
    credentials: 'include',
});
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: GQL_SERVER,
});

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                {/* <Route path="/" element={<HomePage />} /> */}
                <Route path="/" element={<SearchPage />} />
                <Route
                    path="*"
                    element={
                        <main style={{ padding: '1rem' }}>
                            <p>There&apos;s nothing here!</p>
                        </main>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
