interface Units {
    [unit: string]: number;
}

const rtfUnits: Units = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: (24 * 60 * 60 * 1000 * 365) / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
};

export function RelativeTime(d1: Date, d2: Date): string {
    // TODO: Make this take settings as argument
    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    // https://stackoverflow.com/questions/14980014/how-can-i-calculate-the-time-between-2-dates-in-typescript
    const elapsedTime = d1.valueOf() - d2.valueOf();

    for (const u in rtfUnits) {
        if (Math.abs(elapsedTime) > rtfUnits[u]) {
            return rtf.format(
                Math.round(elapsedTime / rtfUnits[u]),
                u as Intl.RelativeTimeFormatUnit
            ); // stupid ts
        }
    }
    return '';
}
