import * as React from 'react';

import { Quote, User } from "src/generated/graphql";
import { useGuildUsersContext } from "src/api/users/guild-users-context";

import { RelativeTime } from 'src/utils/time';
import ReactMarkdown from 'react-markdown';
import style from './index.module.scss';

const GENERIC_DISCORD_AVATAR = 'https://cdn.discordapp.com/embed/avatars/0.png';

export interface QuoteItemProps {
    quote: Quote;
}

export function QuoteItem(props: QuoteItemProps): JSX.Element {
    const { quote } = props;
    const guildUsers = useGuildUsersContext();

    if (!quote.message) {
        return <></>;
    }

    let author: User = {
        _id: { user: quote.author, guild: quote.guild },
        avatarUrl: GENERIC_DISCORD_AVATAR,
        displayName: "Unknown"
    };

    let quoter = "Unknown";

    if (author._id?.user !== null &&
        author._id?.user !== undefined &&
        author._id.user in guildUsers.data) {
        author = guildUsers.data[author._id.user];
    }

    if (quote.quoter !== null &&
        quote.quoter !== undefined &&
        quote.quoter in guildUsers.data) {
        quoter = guildUsers.data[quote.quoter].displayName ?? "Unknown";
    }

    return (
        <div className={style.quoteItem}>
            <div className={style.quoteAuthorRow}>
                <img className={style.quoteAuthorAvatar} src={author.avatarUrl ?? GENERIC_DISCORD_AVATAR} />
                <span className={style.quoteAuthorName}>{author.displayName}</span>
            </div>
            <div className={style.quoteMessage}>
                <ReactMarkdown>{quote.message}</ReactMarkdown>
            </div>
            <div className={style.quoteInfoRow}>
                <span>
                    Quoted by {quoter} |
                </span>
                <span className={style.timestamp}>
                    {RelativeTime(new Date(quote.timestamp), new Date())}
                </span>
                <span className={style.quoteId}>| ID: {quote.seq}</span>
            </div>
            <div className={style.quoteMeta}>
                <span className={style.quoteViews}>
                    Views: {quote.stats?.likes.length ?? 0}
                </span>
                <span className={style.quoteLikes}>
                    Likes: {quote.stats?.likes.length ?? 0}
                </span>
                <span className={style.quoteDislikes}>
                    Dislikes: {quote.stats?.dislikes.length ?? 0}
                </span>
            </div>
        </div>
    );
}