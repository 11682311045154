import * as React from 'react';

import { Config } from 'src/config';
import {
    GuildUsersContext,
    useGuildUsersContextValue,
} from 'src/api/users/guild-users-context';
import { Quote } from 'src/generated/graphql';
import { useGetQuotes } from 'src/api/quotes/use-get-quotes';

import { QuoteItem } from 'src/components/quote-item';
import style from './index.module.scss';

interface DiscordGuild {
    id: string;
    name: string;
    admin: boolean;
}

const NUMQUOTES = 13;

export function SearchPage(): JSX.Element {
    const [guilds, setGuilds] = React.useState<DiscordGuild[]>([]);
    const [quotes, setQuotes] = React.useState<Quote[]>([]);
    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(0);
    const [numResults, setNumResults] = React.useState(0);
    const getQuotes = useGetQuotes();
    const guildUsers = useGuildUsersContextValue({ guildId: '' });

    React.useEffect(() => {
        async function getGuilds(): Promise<void> {
            await fetch(`${Config.restApiBase}/guilds`, {
                credentials: 'include',
            })
                .then((res) => {
                    if (!res.ok) {
                        // TODO: Treat Error + Display Error
                        throw new Error(res.statusText);
                    }
                    return res.json();
                })
                .then((data: DiscordGuild[]) => {
                    // TODO: Fix this terrible promise
                    setGuilds(data);
                    if (data[0]) {
                        guildUsers.fetchForGuild(data[0].id);
                    }
                });
        }

        getGuilds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function searchQuote(): Promise<void> {
        // TODO: Allow selection of multiple guilds
        // TODO: Set loading
        const guildId = guilds[0].id;
        const searchedQuotes = await getQuotes.searchQuotes(
            guildId,
            searchText,
            currentPage * NUMQUOTES,
            NUMQUOTES
        );
        if (searchedQuotes) {
            setCurrentPage(0);
            setQuotes(searchedQuotes.results);
            setNumResults(searchedQuotes.totalResultCount);
        }
        // TODO: Present error for fail case
    }

    async function handleSubmit(
        e: React.FormEvent<HTMLFormElement>
    ): Promise<void> {
        e.preventDefault();
        if (!guilds || !searchText) {
            // TODO: Return error to user
            return;
        }
        await searchQuote();
    }

    async function handleKeyPress(
        e: React.KeyboardEvent<HTMLInputElement>
    ): Promise<void> {
        if (e.key === 'Enter') {
            await searchQuote();
        }
    }

    async function changePage(page: number): Promise<void> {
        // TODO: See handleSubmit, same applies
        setCurrentPage(page);
        const guildId = guilds[0].id;
        const searchedQuotes = await getQuotes.searchQuotes(
            guildId,
            searchText,
            page * NUMQUOTES,
            NUMQUOTES
        );
        if (searchedQuotes) {
            setQuotes(searchedQuotes.results);
        }
    }

    async function getRandom(): Promise<void> {
        // TODO: Set loading
        const guildId = guilds[0].id;
        const randomQuote = await getQuotes.getRandomQuote(guildId);
        if (randomQuote) {
            setCurrentPage(0);
            setQuotes([randomQuote]);
            setNumResults(1);
        }
        // TODO: Present error for fail case
    }

    let pageButtons;
    if (quotes.length > 1) {
        pageButtons = (
            <div className={style.pageButtons}>
                {currentPage > 0 && (
                    <a
                        onClick={(e): void => {
                            e.preventDefault();
                            changePage(currentPage - 1);
                        }}
                    >
                        &lt;&lt;
                    </a>
                )}{' '}
                Page {currentPage + 1}{' '}
                {numResults === NUMQUOTES * (currentPage + 1) && (
                    <a
                        onClick={(e): void => {
                            e.preventDefault();
                            changePage(currentPage + 1);
                        }}
                    >
                        &gt;&gt;
                    </a>
                )}
            </div>
        );
    }

    const quoteItems = quotes.map((quote, idx) => {
        return <QuoteItem quote={quote} key={idx} />;
    });

    return (
        <GuildUsersContext.Provider value={guildUsers}>
            <div className={style.searchPageContainer}>
                <div className={style.loginLink}>
                    <a href="/login">
                        Login
                    </a>
                </div>

                <div className={style.searchNav}>
                    <form
                        onSubmit={handleSubmit}
                        className={style.searchForm}
                        role="search"
                    >
                        <input
                            type="search"
                            value={searchText}
                            onChange={(e): void =>
                                setSearchText(e.target.value)
                            }
                            onKeyPress={handleKeyPress}
                            className={style.searchBar}
                            aria-label="Search quotes"
                        />
                        <button type="submit" className={style.submitButton}>
                            Submit
                        </button>
                    </form>
                    <button
                        className={style.randomButton}
                        onClick={getRandom}
                        aria-label="Get random quote"
                    >
                        Random
                    </button>
                </div>
                <div>{quoteItems}</div>
                {pageButtons}
            </div>
        </GuildUsersContext.Provider>
    );
}
