import * as api from 'src/generated/graphql';
import { GetUsersInGuild } from './users.graphql';
import { useApolloClient } from '@apollo/client';

// Partial used here to save getting all of the fields from the graphql, including ones that aren't being used
interface GetUsers {
    getUsersInGuild: (guildId: string) => Promise<Partial<api.User>[] | null>;
}

export function useGetUsers(): GetUsers {
    const client = useApolloClient();

    async function getUsersInGuild(
        guildId: string
    ): Promise<Partial<api.User>[] | null> {
        const result = await client.query<
            api.GetUsersInGuildQuery,
            api.GetUsersInGuildQueryVariables
        >({
            query: GetUsersInGuild,
            fetchPolicy: 'no-cache',
            variables: {
                guildId,
            },
        });
        if (result.data?.users) {
            return result.data.users;
        }

        // TODO: Log errors elsewhere.
        if (result.error) {
            console.error(result.error);
        }

        return null;
    }

    return {
        getUsersInGuild,
    };
}
