import { FetchRandomQuote, SearchQuotes } from './quotes.graphql';
import {
    FetchRandomQuoteQuery,
    FetchRandomQuoteQueryVariables,
    Quote,
    QuotesQueryResponse,
    SearchQuotesQuery,
    SearchQuotesQueryVariables,
} from 'src/generated/graphql';
import { useApolloClient } from '@apollo/client';

interface GetQuotes {
    getRandomQuote: (guildId: string) => Promise<Quote | null>;
    searchQuotes: (
        guildId: string,
        searchMessage: string,
        offset?: number,
        limit?: number
    ) => Promise<QuotesQueryResponse | null>;
}

export function useGetQuotes(): GetQuotes {
    const client = useApolloClient();

    async function getRandomQuote(guildId: string): Promise<Quote | null> {
        const result = await client.query<
            FetchRandomQuoteQuery,
            FetchRandomQuoteQueryVariables
        >({
            query: FetchRandomQuote,
            fetchPolicy: 'no-cache',
            variables: {
                guildId,
            },
        });

        if (result.data?.quote) {
            return result.data.quote;
        }

        // TODO: Log errors elsewhere.
        if (result.error) {
            console.error(result.error);
        }

        return null;
    }

    async function searchQuotes(
        guildId: string,
        searchMessage: string,
        offset?: number,
        limit?: number
    ): Promise<QuotesQueryResponse | null> {
        const result = await client.query<
            SearchQuotesQuery,
            SearchQuotesQueryVariables
        >({
            query: SearchQuotes,
            fetchPolicy: 'no-cache',
            variables: {
                guildId,
                searchStr: searchMessage,
                offset,
                limit,
            },
        });

        if (result.data.quotes) {
            return result.data?.quotes;
        }

        // TODO: Log errors elsewhere.
        if (result.error) {
            console.error(result.error);
        }

        return null;
    }

    return {
        getRandomQuote,
        searchQuotes,
    };
}
