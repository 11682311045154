import * as React from 'react';
import * as api from 'src/generated/graphql';
import { useGetUsers } from './use-get-users';

type GuildUserData = { [name: string]: Partial<api.User> };

export interface GuildUsersContextValue {
    // Map of ID to User
    data: GuildUserData;
    loading: boolean;
    fetchForGuild: (guildId: string) => void;
}

const defaultGuildUsersContextValue: GuildUsersContextValue = {
    data: {},
    loading: false,
    fetchForGuild: (): void => {},
};

export const GuildUsersContext = React.createContext<GuildUsersContextValue>(
    defaultGuildUsersContextValue
);

interface UseGuildUsersContextArgs {
    guildId: string;
}

export function useGuildUsersContextValue(
    args: UseGuildUsersContextArgs
): GuildUsersContextValue {
    const getUsers = useGetUsers();
    const [guildId, setGuildId] = React.useState(args.guildId);
    const [users, setUsers] = React.useState<GuildUserData>({});
    const [loading, setLoading] = React.useState(false);

    const fetchUsers = (): void => {
        setLoading(true);
        if (guildId) {
            const newUsers: GuildUserData = {};
            getUsers.getUsersInGuild(guildId).then((us) => {
                if (us) {
                    us.forEach(
                        // Shouldn't be possible to be empty, unable to be matched if empty anyways
                        (user) => (newUsers[user._id?.user ?? ''] = user)
                    );
                }
            });
            setUsers(newUsers);
        }
        setLoading(false);
    };

    // Kind of a hack?
    React.useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guildId]);

    const fetchForGuild = (gid: string): void => {
        setGuildId(gid);
    };

    return {
        data: users,
        loading,
        fetchForGuild,
    };
}

export function useGuildUsersContext(): GuildUsersContextValue {
    return React.useContext(GuildUsersContext);
}

type GuildUsersContextProviderProps = React.PropsWithChildren<{
    args: UseGuildUsersContextArgs;
}>;

export function GuildUsersContextProvider(
    props: GuildUsersContextProviderProps
): JSX.Element {
    const value = useGuildUsersContextValue(props.args);
    return React.createElement(
        GuildUsersContext.Provider,
        { value },
        props.children
    );
}
