import * as React from 'react';
import { Config } from 'src/config';

const QUOTEBOT_REST_LOGIN = `${Config.restApiBase}/login`;

export function LoginPage(): JSX.Element {
    // Redirect straight to the REST login link
    window.location.href = QUOTEBOT_REST_LOGIN;

    return (
        <div />
    );
}
